import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "app-section" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "is-title is-size-3 has-text-centered" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_class = _resolveDirective("class")

  return _withDirectives((_openBlock(), _createBlock("section", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      (_ctx.title !== '')
        ? (_openBlock(), _createBlock("div", _hoisted_3, [
            _createVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1)
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "title"),
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 512)), [
    [_directive_class, `has-background-${_ctx.background}`]
  ])
}